<template>
	<div id="footer">
		<div class="footer-row">
			<img
				src="@/assets/GBPRO@2x.png"
				class="footer-img"
				alt="logo" />
			<div class="footer-name">GB WhatsApp</div>
		</div>

		<div class="copyright">
			<p>
				Copyright © 2024
				<a href="/">GB WhatsApp</a>
				<sup>®</sup>
				. All rights reserved.
			</p>
		</div>

		<div
			class="terms-lang-switch footer-page"
			v-if="isPrivacy(['whatsapppro.org', 'waproapk.net'])">
			<ul class="terms list-inline-w-separator">
				<li>
					<a
						href="/privacy"
						title="Privacy Policy">
						Privacy Policy
					</a>
				</li>
				<li>
					<a
						href="/disclaimer"
						title="Disclaimer">
						Disclaimer
					</a>
				</li>
				<li>
					<a
						href="/about-us"
						title="About Us">
						About Us
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'FooterComponent',
	computed: {
		...mapGetters(['showDebug', 'host']),
		// isValidHost() {
		//   console.log('host: ', this.host)
		// 	const validLocalhost = /^localhost(:\d+)?$/
		// 	const domain_12 = /^(https?:\/\/)?(www\.)?whatsapppro\.org$/
		// 	const domain_11 = /^(https?:\/\/)?(www\.)?waproapk\.net$/
		// 	if (!this.host) {
		// 		return false
		// 	}

		// 	return validLocalhost.test(this.host) || domain_12.test(this.host)
		// },
	},
	methods: {
		isPrivacy(arr) {
			console.log('isPrivacy: ', arr)
			if (this.host.length === 0) return true
			// const validLocalhost = /^localhost(:\d+)?$/

			return arr.some((e) => {
				return this.host.indexOf(e) >= 0
			})
		},
	},
}
</script>
